/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.logo {
  height: 150px;
  object-fit: contain;
  padding: 30px;
}

hr {
  background-color: rgba(var(--ion-text-color-rgb, 0,0,0), 0.5);
}

ion-text.form-error {
  display: block;
  padding-top: 4px;
}

ion-fab-button {
  outline: none;
}

.start-page-event-select-alert .alert-wrapper {
  --width: 300px;
  --max-width: none;
}


@media print {
   ion-tab-bar {
     display: none;
   }
}
